import React from "react";

function AppDownload() {
  return (
    <div className="relative w-screen h-screen">
      <img
        src="/images/background-4.jpg"
        className="hidden md:block w-full h-full object-cover"
        alt="Background"
      />
      <div className="absolute top-0 left-0 w-full h-[50px] bg-gradient-to-b from-[#271e1f] to-transparent"></div>
      <div className="absolute bottom-0 left-0 w-full h-[50px] bg-gradient-to-t from-[#271e1f] to-transparent"></div>

      <img
        src="/images/mobile/background-4.png"
        className="block md:hidden w-full h-full object-cover"
        alt="Mobile Background"
      />

      <div className="absolute top-0 right-0 flex items-center justify-center h-full px-4 sm:px-8 lg:px-16 hidden sm:block">
        <div className="mt-10 sm:mt-16 lg:mt-20 container mx-auto text-left">
          <div className="relative bg-black bg-opacity-50 text-white p-6 sm:p-8 lg:p-10 rounded-lg max-w-xs sm:max-w-md lg:max-w-lg mx-auto">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-light mb-2 text-theme">
              DISCOVER
            </h1>
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-light mb-2 text-theme">
              A WORLD OF TRIPS
            </h1>
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-4 text-theme">
              WITH TRIP ME.
            </h2>
            <p className="text-base sm:text-lg lg:text-xl leading-relaxed mb-6">
              TripMe stands out by combining personalized travel experiences
              with cutting-edge technology, all under the trusted umbrella of
              Trip Me Ceylon (Private) Limited.
            </p>
            <div className="flex gap-4 justify-center sm:justify-start">
              <a
                href="https://onelink.to/67e9f2"
                className="py-2 rounded flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition-all hover:scale-110"
              >
                <img
                  src="/images/apple.png"
                  alt="App Store"
                  className="w-15 h-10"
                />
              </a>
              <a
                href="https://onelink.to/67e9f2"
                className="py-2 px-4 rounded flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition-all hover:scale-110"
              >
                <img
                  src="/images/google.png"
                  alt="Google Play"
                  className="w-15 h-10"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute top-1 left-0 right-0 flex items-start justify-center w-full h-full px-4 md:px-16 lg:hidden z-50 mb-16">
        <div className="container mx-auto text-left">
          <div className="relative bg-black bg-opacity-50 text-white p-8 rounded-lg max-w-lg mx-auto mt-5">
            <h1 className="text-3xl font-semibold mb-2 text-theme">DISCOVER</h1>
            <h1 className="text-3xl font-semibold mb-2 text-theme">
              A WORLD OF TRIPS
            </h1>
            <h2 className="text-xl font-bold mb-4 text-theme">WITH TRIP ME.</h2>
            <p className="text-base leading-relaxed mb-6">
              TripMe stands out by combining personalized travel experiences
              with cutting-edge technology, all under the trusted umbrella of
              Trip Me Ceylon (Private) Limited.
            </p>
            <div className="flex gap-4 justify-center">
              <a
                href="https://onelink.to/67e9f2"
                className="py-2 rounded flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition-all hover:scale-110"
              >
                <img
                  src="/images/apple.png"
                  alt="App Store"
                  className="w-40 h-35"
                />
              </a>
              <a
                href="https://onelink.to/67e9f2"
                className="py-2 px-4 rounded flex items-center justify-center gap-2 shadow-md hover:shadow-lg transition-all hover:scale-110"
              >
                <img
                  src="/images/google.png"
                  alt="Google Play"
                  className="w-40 h-35"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppDownload;
