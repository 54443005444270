import React, { useEffect, useState } from "react";
import Footer from "../../components/home/footer";
import { Link } from "react-router-dom";

function AboutPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflowX = "hidden";
  }, []);

  return (
    <div className="container">
      <div className="w-screen h-screen relative">
        <nav className="bg-black border-gray-200 dark:bg-gray-900 absolute top-0 left-0 right-0 z-50 w-full">
          <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4 py-4">
            <div className="flex items-center">
              <a
                href="https://partner.tripme.lk/sign-in"
                className="hidden md:inline-block"
              >
                <button
                  type="button"
                  className="text-white bg-theme hover:bg-theme focus:ring-4 focus:outline-none focus:ring-theme font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 transform hover:scale-105 transition-transform duration-200"
                >
                  Become a Partner
                </button>
              </a>
            </div>

            <Link
              to="https://www.tripme.lk/"
              className="flex items-center justify-center"
            >
              <img
                src="/images/logo-2.png"
                className="h-16 w-auto"
                alt="Logo"
              />
            </Link>

            <div className="mr-10 hidden md:flex space-x-4 items-center">
              {["facebook", "instagram", "tiktok"].map((icon) => (
                <Link
                  key={icon}
                  to={`https://www.${icon}.com`}
                  className="hover:scale-110 transition-transform duration-300"
                >
                  <img
                    src={`/images/icons/${icon}.png`}
                    className="h-7 w-auto"
                    alt={icon}
                  />
                </Link>
              ))}
            </div>

            <button
              type="button"
              className="md:hidden inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

          {isMenuOpen && (
            <div className="md:hidden bg-white z-100 shadow-lg mt-2 p-4">
              <ul className="space-y-4 text-gray-800">
                <li>
                  <Link to="/" className="block text-lg hover:text-theme">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="block text-lg hover:text-theme">
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/refund-policy"
                    className="block text-lg hover:text-theme "
                  >
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="block text-lg hover:text-theme"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-and-conditions"
                    className="block text-lg hover:text-theme"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://partner.tripme.lk/sign-in"
                    className="block text-lg hover:text-theme text-theme"
                  >
                    Become a partner
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </nav>

        <div className="pt-16 pb-5 flex flex-col items-center justify-center">
          <img
            src="/images/About.png"
            alt="Description of the image"
            className="w-full max-w-full shadow-lg"
          />

          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="p-5 lg:w-full w-full mt-2 lg:mt-0 text-left ">
              <h2 className="text-3xl font-bold mb-4">Help Center</h2>
              
              
            </div>
            
          </div>
          <h2 className="text-xl font-bold mb-4">Hotel Partner</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8 px-5">
            
            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/4Y1hiR1WM_A"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to become a Hotel Partner</h3>
            </div>

            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/qTK94Nz-Lvc?si=8UrpTIEaDk0yD3Ik"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to Verify your account</h3>
            </div>

            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="ttps://www.youtube.com/embed/e5ES-mg7poo?si=8kJV2tq-kotGzVsX"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to add your Hotel </h3>
            </div>

            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/yqWX86uT5jM?si=JTk6KwQwXGm2KVqA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to see your bookings</h3>
            </div>
          </div>
          <h2 className="text-xl font-bold mb-4 mt-4">Activity Partner</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8 px-5">
            
            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/4Y1hiR1WM_A"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to become a Activity Partner</h3>
            </div>

            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/qTK94Nz-Lvc?si=8UrpTIEaDk0yD3Ik"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to Verify your account</h3>
            </div>

            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/g0209MKfTKk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to add your Activity </h3>
            </div>

            <div className="flex flex-col items-center text-center">
              <iframe
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/yqWX86uT5jM?si=JTk6KwQwXGm2KVqA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h3 className="text-lg font-bold mt-4">How to see your bookings</h3>
            </div>
          </div>
          
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
