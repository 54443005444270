import { Mail, MapPinHouse, Phone } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
        <div className="space-y-4 text-center md:text-left">
          <h3 className="text-lg font-bold text-theme">USEFUL LINKS</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/about" className="hover:text-theme">
                About Us
              </Link>
            </li>
            <li>
              <a href="/refund-policy" className="hover:text-theme">
                Refund Policy
              </a>
            </li>
            <li>
              <a href="/privacy-policy" className="hover:text-theme">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="terms-and-conditions" className="hover:text-theme">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href="help-center" className="hover:text-theme">
                Help Center
              </a>
            </li>
          </ul>
        </div>

        <div className="text-center lg:block hidden">
          <img src="/images/logo-2.png" alt="Trip Me Logo" className="mb-2" />
        </div>

        <div className="text-center md:text-left space-y-4">
          <h3 className="text-lg font-bold text-theme">CONTACT</h3>
          <ul className="space-y-2">
            <li className="flex justify-center md:justify-start items-center">
              <Phone size={20} color="#ffffff" />
              <Link to="tel:+94701505905" className="text-gray-400 ml-3">
                +94 701 505 905
              </Link>
            </li>

            <li className="flex justify-center md:justify-start items-center">
              <Mail size={20} color="#ffffff" />
              <a href="mailto:info@tripme.lk" className="text-gray-400 ml-3">
                info@tripme.lk
              </a>
            </li>

            <li className="flex justify-center md:justify-start items-center">
              <MapPinHouse size={20} color="#ffffff" />
              <span className="text-gray-400 ml-3">
              No 519, 5th Floor, Janajaya City, Rajagiriya
              </span>
            </li>
          </ul>
        </div>

        <div className="mr-2 flex justify-center lg:hidden">
          <img src="/images/logo-2.png" alt="Trip Me Logo" className="mr-2" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
