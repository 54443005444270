import React from 'react'
import App from '../../App'
import About from '../../components/home/about'
import Scan from '../../components/home/scan'
import Club from '../../components/home/club'
import AppDownload from '../../components/home/appdetails'
import Footer_Header from '../../components/home/adventure'
import Footer from '../../components/home/footer'
import Hero from '../../components/home/hero'

function HomePage() {
  return (
   <div>
    <Hero />
    <About />
    <Scan />
    <Club />
    <AppDownload />
    <Footer_Header />
    <Footer />
   </div>
  )
}

export default HomePage