import React from 'react';


import { BrowserRouter , Route, Routes } from 'react-router-dom';
import HomePage from './App/home/page';
import AboutPage from './App/about/page';
import PrivacyPage from './App/privacy/page';
import ReturnPage from './App/return/page';
import TermsPage from './App/terms/page';
import HelpPage from './App/help/page';


const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/privacy-policy" element={<PrivacyPage />} />
      <Route path="/refund-policy" element={<ReturnPage />} />
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="/help-center" element={<HelpPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
