import React from "react";

function Scan() {
  return (
    <div className=" bg-[#271e1b] flex items-center justify-center">
      <img
        src="/images/mobile/clubtripbanner.png"
        className=" w-full h-full object-cover"
        alt="Mobile Background"
      />
    </div>
  );
}

export default Scan;
